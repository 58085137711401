import React, { useRef, useCallback, useState } from "react";
import ReactDOM from "react-dom/client";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import { Destination, remove, sort, update } from "./destinationSlice";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Table,
  Tag,
  Tooltip,
} from "antd";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import DestinationForm from "./DestinationForm";
import {
  EditOutlined,
  SaveOutlined,
  UndoOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import ExportButton from "./ExportButton";
import ImportButton from "./ImportButton";
import CopyButton from "./CopyButton";
import ClearButton from "./ClearButton";
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text";
  record: Destination;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

interface DraggableBodyRowProps
  extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

const type = "DraggableBodyRow";

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? " drop-over-downward" : " drop-over-upward",
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ""}`}
      style={{ cursor: "move", ...style }}
      {...restProps}
    />
  );
};

function DestinationList() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const destinations = useSelector(
    (state: RootState) => state.destinations.destinations
  );

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      let d = [...destinations];
      const dragRow = d[dragIndex];
      d.splice(dragIndex, 1);
      d.splice(hoverIndex, 0, dragRow);
      dispatch(sort(d));
    },
    [destinations]
  );

  const components = {
    body: {
      row: DraggableBodyRow,
      cell: EditableCell,
    },
  };

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: Destination) => record.id === editingKey;
  const edit = (record: Destination) => {
    form.setFieldsValue(record);
    setEditingKey(record?.id || "");
  };
  const cancel = () => {
    setEditingKey("");
  };

  const save = async (destination: Destination) => {
    try {
      const row = (await form.validateFields()) as Destination;
      dispatch(update({ id: destination.id, data: row }));
      setEditingKey("");
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const columns = [
    {
      title: "Naam",
      dataIndex: "name",
      className: "drag-visible",
      editable: true,
    },
    {
      title: "Plaats",
      dataIndex: "city",
      editable: true,
      render: (value: any, destination: Destination) => {
        return {
          props: {
            style: { lineHeight: 1.0 },
          },
          children: (
            <>
              <Tooltip title={destination.address}>{destination.city}</Tooltip>
            </>
          ),
        };
      },
    },
    {
      title: "Afstand [km]",
      dataIndex: "distance",
    },
    {
      title: "",
      width: 50,
      render: (value: any, destination: Destination) => {
        const deleteDestination = () => {
          dispatch(remove(destination));
        };

        const editable = isEditing(destination);
        return {
          props: {
            style: { lineHeight: 1.0, padding: 0 },
          },
          children: editable ? (
            <span>
              <Button
                onClick={() => save(destination)}
                style={{ marginRight: 2 }}
                icon={<SaveOutlined />}
                size="small"
              />
              <Popconfirm title="Annuleren?" onConfirm={cancel}>
                <Button icon={<UndoOutlined />} size="small" />
              </Popconfirm>
            </span>
          ) : (
            <>
              <Button
                onClick={() => edit(destination)}
                disabled={editingKey !== ""}
                style={{ marginRight: 2 }}
                icon={<EditOutlined />}
                size="small"
              />

              <Popconfirm title="Verwijderen?" onConfirm={deleteDestination}>
                <Button icon={<DeleteOutlined />} size="small" />
              </Popconfirm>
            </>
          ),
        };
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Destination) => ({
        record,
        title: col.title,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <div className="destinationList" style={{ display: "flex" }}>
      <div style={{ flex: 3 }}>
        <CopyButton destinations={destinations} />
        <ImportButton />
        <ExportButton destinations={destinations} />
        <Tag style={{ float: "right", margin: 5 }}>
          {process.env.REACT_APP_VERSION}
        </Tag>
        <ClearButton />
        <DndProvider backend={HTML5Backend}>
          <Form form={form} component={false}>
            <Table
              columns={mergedColumns}
              dataSource={destinations}
              components={components}
              size="small"
              rowKey="id"
              onRow={(_, index) => {
                const attr = {
                  index,
                  moveRow,
                };
                return attr as React.HTMLAttributes<any>;
              }}
              pagination={false}
            />
          </Form>
        </DndProvider>
        <DestinationForm />
        <br />
        <table id="copytable" style={{ display: "none", opacity: 0 }}>
          <tbody>
            {destinations.map((d) => (
              <tr key={`${d.id}`}>
                <td>{d.name}</td>
                <td>{d.city}</td>
                <td>{d.distance ? d.distance.toFixed(0) : ""}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DestinationList;
