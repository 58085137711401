import React, { useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import config from "../../../Config";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store";
import { Destination, setDistance } from "../destinations/destinationSlice";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { DistanceMatrixService } from "@react-google-maps/api";

const center = {
  lat: 51.37442,
  lng: 5.57766,
};
const libraries: ["places"] = ["places"];

function Map() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: config.google.maps.apiKey,
    libraries: libraries,
  });

  const distanceService = isLoaded
    ? new window.google.maps.DistanceMatrixService()
    : null;

  const dispatch = useDispatch();
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const [marker, setMarker] = React.useState<google.maps.LatLng | null>(null);

  const destinations = useSelector(
    (state: RootState) => state.destinations.destinations
  );

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    map.setCenter(center);
    map.setZoom(10);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onDistanceMatrixReceived = React.useCallback(
    (destinations: Destination[]) => {
      return function callback(
        /** The response to a DistanceMatrixService request, consisting of the formatted origin and destination addresses, and a sequence of DistanceMatrixResponseRows, one for each corresponding origin address. */
        response: google.maps.DistanceMatrixResponse | null,
        /** The top-level status about the request in general returned by the DistanceMatrixService upon completion of a distance matrix request. Specify these by value, or by using the constant's name. For example, 'OK' or google.maps.DistanceMatrixStatus.OK. */
        status: google.maps.DistanceMatrixStatus
      ) {
        destinations.forEach((destination, index) => {
          const distance =
            response?.rows?.[0]?.elements?.[index]?.distance?.value ||
            undefined;
          const address = response?.destinationAddresses?.[index] || undefined;
          dispatch(
            setDistance({
              destination: destination,
              distance: distance ? distance / 1e3 : undefined,
              address: address,
            })
          );
        });
      };
    },
    [destinations]
  );

  const onClick = React.useCallback(
    function callback(event: google.maps.MapMouseEvent) {
      destinations.map((destination) => {
        dispatch(
          setDistance({
            destination: destination,
            distance: undefined,
            address: destination.address,
          })
        );
      });

      const chunkSize = 25;
      for (let i = 0; i < destinations.length; i += chunkSize) {
        const chunk = destinations.slice(i, i + chunkSize);
        distanceService!.getDistanceMatrix(
          {
            destinations: chunk.map((d) => `${d.name}, ${d.city}`),
            origins: [event.latLng!],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
          },
          onDistanceMatrixReceived(chunk)
        );
      }
      setMarker(event.latLng);
    },
    [destinations]
  );
  let [searchBox, setSearchBox] = useState<google.maps.places.SearchBox | null>(
    null
  );
  const onSearchBoxLoad = (ref: google.maps.places.SearchBox) => {
    setSearchBox(ref);
  };
  const onPlacesChanged = () => {
    const places = searchBox?.getPlaces() || [];
    if (places.length > 0) {
      const searchResult = places[0];
      if (searchResult.geometry?.location) {
        map?.setCenter(searchResult.geometry?.location);
        map?.setZoom(15);
      }
    }
  };

  return isLoaded ? (
    <>
      <GoogleMap
        mapContainerClassName="MapContainer"
        center={center}
        zoom={1}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={onClick}
        options={{
          gestureHandling: "greedy",
        }}
      >
        <>
          <StandaloneSearchBox
            onLoad={onSearchBoxLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Zoeken"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                left: "50%",
                marginLeft: "-120px",
              }}
            />
          </StandaloneSearchBox>
          {marker ? <Marker position={marker} /> : null}
        </>
      </GoogleMap>
    </>
  ) : (
    <></>
  );
}

export default React.memo(Map);
