import { Button } from "antd";
import { useRef } from "react";
import { add, clearDestinations, Destination } from "./destinationSlice";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";

function ImportButton() {
  const inputFile = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const startImport = () => {
    if (inputFile) {
      inputFile?.current?.click();
    }
  };

  const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(clearDestinations());

    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e?.target?.result;
      let content = JSON.parse(text?.toString() || "[]");
      for (let item of content as Destination[]) {
        if (item.name && item.city) {
          dispatch(
            add({
              id: item?.id || uuidv4(),
              name: item.name,
              city: item.city,
            })
          );
        }
      }
    };
    let filename = event?.target?.files?.[0] || null;
    if (filename) {
      reader.readAsText(filename);
    }
  };
  return (
    <>
      <Button onClick={startImport}>Importeer</Button>
      <input
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={uploadFile}
      />
    </>
  );
}

export default ImportButton;
