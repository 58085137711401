import { Button } from "antd";
import { Destination } from "./destinationSlice";

function CopyButton({ destinations }: { destinations: Destination[] }) {
  const startCopy = () => {
    const permissionName = "clipboard-write" as PermissionName;
    navigator.permissions.query({ name: permissionName }).then((result) => {
      console.log(result);
      if (result.state === "granted" || result.state === "prompt") {
        const type = "text/html";
        const value =
          document.getElementById("copytable")?.outerHTML || "<table></table>";
        const spreadSheetRow = new Blob([value], { type });

        navigator.clipboard.write([
          new ClipboardItem({ "text/html": spreadSheetRow }),
        ]);
      }
    });
  };

  return (
    <Button onClick={startCopy} type="primary">
      Kopieer lijst
    </Button>
  );
}

export default CopyButton;
