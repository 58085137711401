import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

export interface Destination {
  id: string;
  name: string;
  city: string;
  address?: string;
  distance?: number;
}

export interface DestinationState {
  destinations: Destination[];
}

const initialState: DestinationState = {
  destinations: [],
};

const findDestinations = (
  destinations: Destination[],
  destination: Destination
) => {
  return destinations.findIndex(
    (obj) => obj.name == destination.name && obj.city == destination.city
  );
};
const findDestinationsById = (destinations: Destination[], id: string) => {
  return destinations.findIndex((obj) => obj.id == id);
};

export const destinationSlice = createSlice({
  name: "destination",
  initialState,
  reducers: {
    add: (state, action: PayloadAction<Destination>) => {
      state.destinations.push(action.payload);
    },
    remove: (state, action: PayloadAction<Destination>) => {
      const index = findDestinationsById(state.destinations, action.payload.id);
      if (index > -1) {
        state.destinations.splice(index, 1);
      }
    },
    clearDestinations: (state) => {
      state.destinations = [];
    },
    update: (
      state,
      action: PayloadAction<{ id: string; data: Partial<Destination> }>
    ) => {
      const index = findDestinationsById(state.destinations, action.payload.id);
      if (index > -1) {
        state.destinations[index] = {
          ...state.destinations[index],
          ...action.payload.data,
        };
      }
    },
    sort: (state, action: PayloadAction<Destination[]>) => {
      state.destinations = state.destinations.sort((a, b) => {
        return (
          findDestinations(action.payload, a) -
          findDestinations(action.payload, b)
        );
      });
    },
    setDistance: (
      state,
      action: PayloadAction<{
        destination: Destination;
        distance?: number;
        address?: string;
      }>
    ) => {
      const index = findDestinationsById(
        state.destinations,
        action.payload.destination.id
      );
      if (index > -1) {
        state.destinations[index].distance = action.payload.distance;
        state.destinations[index].address = action.payload.address;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { add, update, remove, clearDestinations, sort, setDistance } =
  destinationSlice.actions;

export default destinationSlice.reducer;
